<template>
  <div class="contents">
    <CmsPage v-if="!pending && page" :page :components />

    <GenericPageCinemas v-else-if="!pending && !page" />
  </div>
</template>

<script lang="ts" setup>
const { pending, page, components } = await useCmsPageFetch()

defineI18nRoute({
  paths: {
    en: '/cinemas',
    de: '/kinos',
    es: '/cines',
  },
})

defineOptions({
  name: 'PageCinemas',
})
</script>
