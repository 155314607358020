<template>
  <div class="contents">
    <CmsPage v-if="!pending && page" :page :components />

    <GenericPageCities v-else-if="!pending && !page" />
  </div>
</template>

<script lang="ts" setup>
const { pending, page, components } = await useCmsPageFetch()

defineI18nRoute({
  paths: {
    en: '/cities',
    de: '/staedte',
    es: '/ciudades',
  },
})

defineOptions({
  name: 'PageCities',
})
</script>
