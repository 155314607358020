<template>
  <div class="contents">
    <CmsPage v-if="!pending && page" :page :components />

    <GenericPageHomepage v-else-if="!pending && !page" />
  </div>
</template>

<script lang="ts" setup>
const { pending, page, components } = await useCmsPageFetch()

defineI18nRoute({
  paths: {
    en: '/',
    de: '/',
    es: '/',
  },
})

defineOptions({
  name: 'PageHomepage',
})
</script>
