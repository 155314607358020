import type { Show, Flag } from '#gql/default'

export default function useShowLink({
  show,
  detailLink,
  bookingModal,
}: {
  show: Show & { flags: (Flag & { isHidden?: boolean })[] }
  detailLink: Ref
  bookingModal: boolean
}) {
  const cmsConfig = useCmsConfig()
  const { now } = useServerTime()
  const router = useRouter()
  const { $gtm } = useNuxtApp()

  const isBookingModalVisible = ref(false)
  const isInfoModalVisible = ref(false)

  const isExpired = computed(() => new Date(show.endsale).getTime() < now.value)

  const isExternal = computed(() =>
    ['THIRD_PARTY', 'DISABLED'].includes(show.cinema?.bookingType ?? '')
  )

  const isMidnightShow = computed(
    () => parseInt(show.beginning.substring(0, 2), 10) < 6
  )

  const hasCinemaSpecificFlags = computed(() =>
    (show.flags ?? []).some(({ isCinemaSpecific }) => isCinemaSpecific)
  )

  const bookingLink = computed(() =>
    isExternal.value ? show.deeplink : detailLink.value?.fullPath
  )

  const flags = computed(() =>
    show.flags.filter((flag: Flag & { isHidden?: boolean }) => !flag.isHidden)
  )

  const isSaleActive = computed(() => {
    if (!show.startsale) {
      return true
    }

    return new Date(show.startsale).getTime() < now.value
  })

  const cssClasses = computed(() => {
    return {
      'pointer-events-none opacity-25': isExpired.value,
      'cursor-pointer transition-colors duration-150': !isExpired.value,
    }
  })

  function handleClick() {
    $gtm.push({
      event: 'click_show_detail_button',
      cinema_name: show.cinema?.name,
      cinema_booking_type: show.cinema?.bookingType,
      show_beginning: show.beginning.substring(11, 16),
      show_name: show.name,
      date: show.beginning.substring(0, 10),
    })

    if (!isSaleActive.value) {
      isInfoModalVisible.value = true
      return
    }

    if (cmsConfig.value?.scope === CMS_CONFIG_SCOPE.PORTAL) {
      if (
        isExternal.value ||
        hasCinemaSpecificFlags.value ||
        isMidnightShow.value
      ) {
        isInfoModalVisible.value = true
      } else if (bookingModal && !isExternal.value) {
        isBookingModalVisible.value = true
      } else if (isExternal.value) {
        window.location.href = bookingLink.value!
      } else {
        router.push(bookingLink.value!)
      }
    } else {
      router.push(bookingLink.value!)
    }
  }

  return {
    isBookingModalVisible,
    isExpired,
    isInfoModalVisible,
    isExternal,
    isMidnightShow,
    isSaleActive,
    hasCinemaSpecificFlags,
    bookingLink,
    handleClick,
    flags,
    cssClasses,
  }
}
