<template>
  <PageContainer>
    <PageGrid
      :gap="{
        default: PAGE_GRID_GAP_SIZE.LG,
        sm: PAGE_GRID_GAP_SIZE.LG,
        md: PAGE_GRID_GAP_SIZE.LG,
        lg: PAGE_GRID_GAP_SIZE.LG,
      }"
    >
      <PageGridColumn>
        <PageHeading :title="t('title')" />
        <div class="space-y-6">
          <SearchPageFilters />
          <template v-if="!pending">
            <template v-if="!noResults">
              <SearchPageResultMovies
                v-if="movies.length"
                :movies
                :layout="moviesLayout"
              />
              <SearchPageResultCinemas
                v-if="cinemas.length"
                :cinemas
                :layout="cinemasLayout"
              />
              <SearchPageResultCities v-if="cities.length" :cities />
              <SearchPageResultShows v-if="shows.length" :shows :limit="20" />
            </template>
            <div v-else>
              {{ t('noResults') }}

              <PageSectionHeading
                class="mb-4 mt-14"
                :tag="PAGE_HEADING_TAG.H3"
                :title="t('nowPlaying')"
              />
              <CatalogMovieList
                :layout="MOVIE_LIST_LAYOUT.HORIZONTAL"
                :items-per-page="10"
                :movie-list-id="config.movieListIds.NOW_PLAYING"
              />
            </div>
          </template>
        </div>
      </PageGridColumn>
    </PageGrid>
  </PageContainer>
</template>

<script lang="ts" setup>
import type { Location, SearchTypeEnum } from '#gql/default'

const { t } = useI18n()

const { city } = await useCity()
const route = useRoute()
const config = useAppConfig()

const { fetchParams } = useFetchParams({
  filters: [URL_FILTER.TYPES, URL_FILTER.CITY],
  preselected: {
    search: route.query.search as string,
  },
})

const moviesLayout = computed(() => {
  const { types } = fetchParams.value

  return types?.includes(SEARCH_TYPE_ENUM.MOVIE)
    ? MOVIE_CARDS_LAYOUT.VERTICAL
    : MOVIE_CARDS_LAYOUT.HORIZONTAL
})

const cinemasLayout = computed(() => {
  const { types } = fetchParams.value

  return types?.includes(SEARCH_TYPE_ENUM.MOVIE)
    ? CINEMA_CARDS_LAYOUT.VERTICAL
    : CINEMA_CARDS_LAYOUT.HORIZONTAL
})

const variables = computed(() => {
  const { search, types } = fetchParams.value

  return {
    query: search,
    limit: 100,
    types: types as SearchTypeEnum[],
    location: city.value
      ? ({
          latitude: city.value.latitude,
          longitude: city.value.longitude,
        } as Location)
      : undefined,
  }
})

const { movies, shows, cinemas, cities, pending, noResults } = await useSearch2(
  {
    operation: 'FetchSearchForSearchPage',
    variables,
  }
)

usePageMetas({
  title: t('title'),
})

defineI18nRoute({
  paths: {
    en: '/search',
    de: '/suche',
    es: '/buscar',
  },
})

defineOptions({
  name: 'PageSearch',
})
</script>

<i18n>
de:
  title: "Suche"
  noResults: "Keine Ergebnisse gefunden"
  nowPlaying: "Jetzt im Kino"
es:
  title: "Búsqueda"
  noResults: "No se encontraron resultados"
  nowPlaying: "Ahora en el cine"
</i18n>
