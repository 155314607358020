<template>
  <div data-catalog-cities class="space-y-6">
    <div
      class="scrollbar-none group-data-[default-spacing=true]:container-bleed overflow-x-auto group-data-[default-spacing=true]:pl-5 sm:contents"
    >
      <ul class="flex sm:flex-wrap">
        <li>
          <FiltersSearch
            :always-open="true"
            :placeholder="t('searchPlaceholder')"
            :url-prefix
          />
        </li>
      </ul>
    </div>

    <CityListVertical :cities :paginator-info :url-prefix :next :prev />
  </div>
</template>

<script lang="ts" setup>
interface Props {
  urlPrefix?: string
  itemsPerPage?: number
}

const { urlPrefix, itemsPerPage } = defineProps<Props>()
const { t } = useI18n()

const { cities, paginatorInfo, next, prev } = await useCities2({
  fetchOperation: 'FetchCities',
  urlPrefix,
  preselected: {},
  first: itemsPerPage,
})

defineOptions({
  name: 'CatalogCities',
})
</script>

<i18n>
de:
  searchPlaceholder: "Stadt/PLZ suchen"
es:
  searchPlaceholder: "Buscar ciudad/código postal"
</i18n>
