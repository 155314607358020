export default function useServerTime() {
  const serverTime = useServerTimeState()

  async function fetchServerTime() {
    const response = await fetch('/api/kinoheld', {
      method: 'GET',
    })
    const serverDate = response.headers.get('Date')!
    const time = new Date(serverDate).getTime()
    const diff = Math.floor(time - new Date().getTime())

    serverTime.value = {
      time,
      diff,
    }
  }

  const now = computed(() =>
    serverTime.value?.diff ? Date.now() + serverTime.value.diff : Date.now()
  )

  return {
    now,
    serverTime,
    fetchServerTime,
  }
}
