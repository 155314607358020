<template>
  <div class="contents">
    <CmsPage v-if="!pending && page" :page :components />
  </div>
</template>

<script setup lang="ts">
const { pending, page, components } = await useCmsPageFetch(true)

defineOptions({
  name: 'PageSlug',
})
</script>
